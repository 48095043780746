export default function get(that, url, filters = {}) {
  let response = null
  let optionString = ''
  let firstFilter
  // eslint-disable-next-line no-prototype-builtins
  if (Object.keys(filters).length) {
    optionString = '?'
    firstFilter = true
  }
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in filters) {
    if (firstFilter) {
      firstFilter = false
    } else {
      optionString += '&'
    }
    optionString += `${key}=${filters[key]}`
  }
  try {
    // response = that.$http.get(`${that.apiUrl}caisse/tickets?date_debut=${filters.date_debut}&date_fin=${filters.date_fin}`)
    response = that.$http.get(url + optionString)
  } catch (error) {
    console.log(error)
  }
  return response
}
