import get from '@/api-services/generals'

export default function getPatients(that, filters = {}) {
  return get(that, `${that.apiUrl}patients/`, filters)
}

export function getPatient(that, id) {
  return get(that, `${that.apiUrl}patients/${id}`, {})
}

// DossierConsultation
// Consultation
//
// ConsultationExamen
// ConsultationPrescription
// api/consultation/ dossier
// api/consultation/ examen
// api/consultation/ prescription
